<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <h5>Avez-vous un handicap particulier?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-check form-check-inline">
                <input
                  v-model="estHandicape"
                  class="form-check-input"
                  type="radio"
                  id="estHandicape1"
                  name="estHandicape"
                  :value="true"
                />
                <label class="form-check-label" for="estHandicape1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="estHandicape"
                  class="form-check-input"
                  type="radio"
                  id="estHandicape2"
                  name="estHandicape"
                  :value="false"
                />
                <label class="form-check-label" for="estHandicape2">Non</label>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div v-if="estHandicape" class="col-sm-12">
            <h5>Sélection le type de handicap</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <!-- <select
                  :value="editableItem.typeHandicap"
                  @input="handleInput"
                  class="form-select"
                  id="typeHandicap"
                  name="typeHandicap"
                  aria-label="Sélection le type de handicap"
                >
                  <option :value="n.id" v-for="n in typeHandicaps" :key="n.id">
                    {{ n.libelle }}
                  </option>
                </select> -->
                <c-select
                  id="typeHandicap"
                  name="typeHandicap"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.typeHandicap"
                  :options="typeHandicaps"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="typeHandicap">Type de handicap</label>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      estHandicape: this.value.estHandicape,
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({
      typeHandicaps: "typeHandicap/typeHandicaps",
    }),
  },
  watch: {
    estHandicape() {
      this.editableItem.estHandicape = this.estHandicape;
      this.$emit("input", this.editableItem);
      if (this.estHandicape !== null && !this.estHandicape) {
        this.editableItem.typeHandicap = null;
      }
    },
  },
  created() {
    this.fetchTypeHandicaps();
  },
  methods: {
    ...mapActions({
      fetchTypeHandicaps: "typeHandicap/fetchTypeHandicaps",
    }),
    handleInput(e) {
      if (e.target.name === "typeHandicap") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
